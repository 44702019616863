import { useEffect, useRef, useState } from 'react';

import { trackEvent, trackLink } from './mixpanel';
import { isUnknownMouseClick } from './mixpanel.utils';

export const useTrackPageView = (pageName: string) => {
  const lastTrackedPage = useRef<string | null>(null);
  useEffect(() => {
    // Track page view only once in dev with strict mode
    if (pageName === lastTrackedPage.current) return;

    trackEvent('page_viewed', {
      page_name: pageName,
    });
    lastTrackedPage.current = pageName;
  }, [pageName]);
};

export const useTrackOtherLinks = () => {
  useEffect(() => {
    function listenForLinkClicks(mouseEvent: MouseEvent) {
      if (!(mouseEvent.target instanceof HTMLElement) || isUnknownMouseClick(mouseEvent)) return;
      const element = mouseEvent.target.closest('a');
      if (!element) return;

      const internalLinks = [window.location.hostname, 'bugbug.featurebase.app', 'bugbug.io'];
      const { href } = element;

      if (!href) return;

      if (!internalLinks.some((domain) => href.includes(domain))) {
        trackLink(mouseEvent, 'outbound_link_clicked', {
          link: href,
        });
        return;
      }

      if (!['app.bugbug.io/sign-up', 'docs.bugbug.io'].some((domain) => href.includes(domain)))
        return;

      const postContainer = element.closest('#post-content');
      if (!postContainer) return;

      const linkNo = Array.from(postContainer.querySelectorAll(`a[href='${href}']`)).indexOf(
        element,
      );
      const postType = postContainer.getAttribute('data-type') ?? 'unknown-resource';
      const postId = postContainer.getAttribute('data-entity-id') ?? 'unknown-id';
      const eventName = href.includes('docs.bugbug.io')
        ? 'documentation_button_clicked'
        : 'sign_up_button_clicked';
      const idPrefix = href.includes('docs.bugbug.io') ? 'docs' : 'sign-up';

      trackLink(mouseEvent, eventName, {
        placement: 'section',
        button_id: `${idPrefix}-${postType}-id-${postId}-link-no-${linkNo + 1}`,
        button_text: element.textContent?.trim() ?? 'missing-label',
      });
    }

    window.addEventListener('click', listenForLinkClicks);
    window.addEventListener('auxclick', listenForLinkClicks);
    return () => {
      window.removeEventListener('click', listenForLinkClicks);
      window.removeEventListener('auxclick', listenForLinkClicks);
    };
  }, []);
};

export const useTrackFormSubmit = (placement: 'contact' | 'book demo') => {
  const formContainerRef = useRef<HTMLElement>(null);
  const [form, setForm] = useState<HTMLFormElement | null>(null);

  useEffect(() => {
    // AC form can be either ready on the first render or still loading
    const intervalId = setInterval(() => {
      const formElement = formContainerRef.current?.querySelector('form');

      if (formElement) {
        setForm(formElement);
        clearInterval(intervalId);
      }
    }, 50);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const trackSubmit = () => {
      const submitButton = form?.querySelector('button');
      const buttonText = submitButton?.textContent ?? 'Send Message';
      trackEvent('send_message_button_clicked', {
        placement,
        button_text: buttonText,
        button_id: submitButton?.id ?? 'missing-id',
      });
    };

    form?.addEventListener('submit', trackSubmit);

    return () => {
      form?.removeEventListener('submit', trackSubmit);
    };
  }, [form, placement]);

  return formContainerRef;
};
