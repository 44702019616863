import mixpanel from 'mixpanel-browser';

import type { MixpanelEvent, MixpanelEventName } from './mixpanel.types';
import type { MouseEvent } from 'react';
import type { MixpanelStrapiEvent } from '~/types/models';

import { isUnknownMouseClick } from './mixpanel.utils';

const isEnabled = !!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
let isInitialzed = false;

const initMixpanel = () => {
  if (isInitialzed) return Promise.resolve();

  return new Promise<void>((resolve) => {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ?? '', {
      ...(process.env.NEXT_PUBLIC_MIXPANEL_URL
        ? { api_host: process.env.NEXT_PUBLIC_MIXPANEL_URL }
        : {}),
      ...(process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' ? { debug: true } : {}),

      track_links_timeout: 500,
      ignore_dnt: true,

      loaded: () => {
        mixpanel.register({
          location: 'Website',
        });

        isInitialzed = true;

        resolve();
      },
    });
  });
};

export const trackEvent = async <T extends MixpanelEventName>(
  name: T,
  ...args: Extract<MixpanelEvent, { name: T }> extends { data: infer TData } ? [TData] : []
) => {
  const data = args[0] ?? {};

  const combinedData = {
    ...data,
    url: window.location.href,
  };

  if (isEnabled) {
    await initMixpanel();
    mixpanel.track(name, combinedData, { send_immediately: true });
  } else {
    // eslint-disable-next-line no-console
    console.log(name, combinedData);
  }
};

export const trackLink = async <T extends MixpanelEventName>(
  e: MouseEvent<HTMLAnchorElement> | globalThis.MouseEvent,
  name: T,
  data: Extract<MixpanelEvent, { name: T }> extends { data: infer TData } ? TData : never,
) => {
  if (!(e.target instanceof HTMLElement)) return;

  const anchor = e.target.closest('a');

  // Narrow down the type
  if (!anchor || !anchor.href) return;

  const openInNewTab = e.button === 1 || e.metaKey || anchor.target === '_blank';
  const targetUrl = anchor.href;
  const trackTimeoutMs = 500;

  const combinedData = {
    ...(data ?? {}),
    url: window.location.href,
  };

  function onTrackSuccess() {
    if (openInNewTab) return;
    window.location.href = targetUrl;
  }

  if (isEnabled) {
    if (!openInNewTab) {
      e.preventDefault();
      setTimeout(onTrackSuccess, trackTimeoutMs);
    }

    await initMixpanel();
    mixpanel.track(name, combinedData, { send_immediately: true }, onTrackSuccess);
  } else {
    // eslint-disable-next-line no-console
    console.log(name, combinedData);
  }
};

export const trackLinkClick = async <
  T extends { event?: MixpanelStrapiEvent; label: string; elementId?: string },
>(
  mouseEvent: MouseEvent<HTMLAnchorElement> | globalThis.MouseEvent,
  content: T,
) => {
  if (!content.event || isUnknownMouseClick(mouseEvent)) return;
  const { name, placement } = content.event;
  trackLink(mouseEvent, name, {
    placement,
    button_id: content.elementId ?? 'missing-id',
    button_text: content.label,
  });
};

export const trackRedirect = async <T extends MixpanelEventName>(
  targetUrl: string,
  name: T,
  data: Extract<MixpanelEvent, { name: T }> extends { data: infer TData } ? TData : never,
) => {
  const trackTimeoutMs = 500;

  const combinedData = {
    ...(data ?? {}),
    url: window.location.href,
  };

  function onTrackSuccess() {
    window.location.href = targetUrl;
  }

  if (isEnabled) {
    setTimeout(onTrackSuccess, trackTimeoutMs);

    await initMixpanel();
    mixpanel.track(name, combinedData, { send_immediately: true }, onTrackSuccess);
  } else {
    // eslint-disable-next-line no-console
    console.log(name, combinedData);
  }
};
